import developer_background from '../../assets/images/developer-background.png'
import AppButton from '../app_button/app_button'
import { Spacer } from '../layout/layout'
import { HeadContentContainer, HeaderImage } from '../merchant_header/merchant_header'


const title = "VPay API"
const content = <>
    Use our API for bulk outward transfer. Our API<br /> can also be used
to receive payments via wallet<br /> per customer.
</>

export default function DeveloperHeader() {
    return (
        <HeaderImage background={developer_background}>
            <HeadContentContainer title={title} content={content}>
                <Spacer height={10} />
                <AppButton className="merchant-header-btn-width header-account-button font-size-20" isActive={true} name='Get Started' />
            </HeadContentContainer>
        </HeaderImage>
    )
}

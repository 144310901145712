import AppIcon from '../app_icon/app_icon';
import navigation from '../../assets/images/navigation.svg';
import './navigation.css';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Spacer } from '../layout/layout';
import useScroll from '../../hooks/use_scroll';
import StoreButton from '../store_button/store_button';

export default function Navigation() {
	const { pathname } = useLocation();
	const [navOpen, setNavOpen] = useState(null);
	const { hasBackground } = useScroll();

	const onNavMenuClick = () => {
		setNavOpen(true);
	}

	const onBodyClick = (e, value) => {
		// console.log({ value })
		setNavOpen(value);
		e.stopPropagation();
	}

	//close nav on new route
	useEffect(() => {
		setNavOpen(null);
	}, [pathname])

	return (
		<div className={`${hasBackground ? 'nav-bg' : 'nav-no-bg'} navigation p-fixed full-width`}>
			<div className='d-flex j-space-between align-center no-mobile app-padding-lr ptb20'>
				<NavigationItem hasBackground={hasBackground} pathname={pathname} />
				<div className='nav-links always-active fw500'><Link to='/signup' style={{ paddingLeft: "15px", paddingRight: "15px" }}>Get Started</Link></div>
			</div>
			<MobileNavigation onBodyClick={onBodyClick} onNavMenuClick={onNavMenuClick} navOpen={navOpen} />
		</div>


	);
}

const MobileNavigation = ({ onNavMenuClick, onBodyClick, navOpen }) => {
	return (
		<div className='no-desktop-but-mobile-flex navigation j-space-between content-padding'>
			<AppIcon />
			<button className='unstyle-button c-pointer' onClick={onNavMenuClick}>
				<img src={navigation} className='navigation-icon' alt='navigation button' />
			</button>
			{
				navOpen !== null &&
				<MobileNavigationItem
					className={navOpen ? 'nav-open' : 'nav-close'}
					onClick={onBodyClick} />
			}

		</div>
	);
}

const NavigationItem = ({ className, hasBackground, pathname }) => {
	return (
		<div className={`${className || ''}`}>
			<div className='main-portion'>
				<div className='content'>
					<ul className='unstyle-ul d-flex centents j-space-between'>
						<div className=''><AppIcon /></div>
						<Spacer width={80} />
						<li className='nav-links fw400'><Link to='/merchant-page'>Merchants</Link></li>
						<Spacer width={40} />
						<li className='nav-links fw400'><Link to='/cashier-page'>Cashiers</Link></li>
						<Spacer width={40} />
						<li className='nav-links fw400'><Link to='/developer-page'>Developers</Link></li>
						<Spacer width={40} />
						<li className='nav-links fw400'><Link to='/login'>Login</Link></li>
						<Spacer width={40} />
					</ul>
				</div>
			</div>
		</div>
	);
}

const MobileNavigationItem = ({ className, onClick }) => {
	return (
		<div className={`p-fixed whole-area ${className || ''}`} onClick={(e) => onClick(e, false)}>
			<div onClick={(e) => onClick(e, true)} className='p-absolute accent-bg-color main-portion'>
				<div className='d-flex content flex-vertical p-absolute d-flex-space-between'>
					<ul className='unstyle-ul'>
						<li className='nav-links fw400'><Link to='/merchant-page'>Merchants</Link></li>
						<Spacer width={40} />
						<li className='nav-links fw400'><Link to='/cashier-page'>Cashiers</Link></li>
						<Spacer width={40} />
						<li className='nav-links fw400'><Link to='/developer-page'>Developers</Link></li>
						<Spacer width={40} />
						<li className='nav-links fw400'><Link to='/login'>Login</Link></li>
						<Spacer width={40} />
						<li className='nav-links always-active'><Link to='/signup'>Create free account</Link></li>
					</ul>
					<StoreButton className='nav-store-button' orientation='vertical' />
				</div>
			</div>
		</div>
	);
}
import AboutAppArea from '../../components/about_app_area/about_app_area';
import AboutFeature from '../../components/about_feature/about_feature';
import AboutHeader from '../../components/about_header/about_header';
import AboutImages from '../../components/about_images/about_images';
import Layout, { Spacer } from '../../components/layout/layout';
export default function About() {
    return (
        <>
            <Layout>
                <AboutHeader />
                <Spacer height={150} className='no-mobile' />
                <AboutImages />
                <Spacer height={50} className='no-mobile' />
                <AboutFeature />
                <Spacer height={80} className='no-mobile' />
                <AboutAppArea />
            </Layout>
        </>
    );
}
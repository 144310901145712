import { useInput } from '../../hooks/use_input';
import AppButton from '../app_button/app_button';
import { Spacer } from '../layout/layout';
import { PageHeader } from '../terms_content/terms_content';

import cardOverlay from '../../assets/images/cardOverlay.svg'

import './contact_content.css';
import { ContactCards } from '../contact_cards/contact_cards';

export default function ContactContent() {
    const subjectProps = useInput('');
    const messageProps = useInput('');

    return (
        <div style={{ padding: "100px 0px" }} >
            <GradientBackground>
                <PageHeader text='Reach out to us; contact@Vpay.cash' />
                <span className="fw400 primary-color font-size-20 line-height12">
                    Welcome! We’re here to help you get started with Vpay.<br />
                    Start a converstation if you have any questions or contact us
                </span>
            </GradientBackground>
            <ContentPadding>
                <div className='d-grid' style={{ gridTemplateColumns: "1fr 1fr 1fr", gridGap: "20px", marginTop: "-50px", justifyContent: "center", maxWidth: "calc(100vw - 400px)" }}>
                    <ContactCards />
                </div>
                <Spacer height={30} />
                <Spacer height={30} />
                <input className='full-width' style={{ opacity: subjectProps.value ? 1 : 0.3, outline: "none", maxWidth: "1240px", width: "calc(100vw - 345px)" }} placeholder='Subject' {...subjectProps} />
                <Spacer height={20} />
                <textarea className='full-width contact-message' style={{ opacity: messageProps.value ? 1 : 0.3, maxWidth: "1240px", maxHeight: "350px", outline: "none", width: "calc(100vw - 345px)" }} placeholder='Message' {...messageProps}></textarea>
                <Spacer height={20} />
                <AppButton name='Send message' className='fw500 contact-button' isActive={true} />
            </ContentPadding>
        </div>
    );
}

export const GradientBackground = ({ children }) => (
    <div className="full-vw p-relative d-flex j-center line-height17 flex-vertical" style={{ height: "350px", background: "linear-gradient(90deg, #AB3882 13.19%, #FF1C26 58.4%, #F79420 100%)", padding: "0px 200px" }}>
        {children}
        <img src={cardOverlay} alt="" className="p-absolute full-height gradient-overlay" />
    </div>
)

export const ContentPadding = ({ children }) => {
    return (
        <div className='contact-padding p-relative d-flex flex-vertical align-center'>
            {children}
        </div>
    );
}
import cardOverlay from '../../assets/images/cardOverlay.svg';

import './dashboard_card.css'

/**
 * 
 * @param {ReactNode} children  
 * @param {string} height - Specifies the height of the container  
 * @param {string} width - Specifies the width of the container  
 * @param {boolean} center - If true, justify Card Children center  
 * @param {string} backgroundColor - Specifies the background color of the container   
 */

export default function DashBoardCard({ children, height, width, background, center }) {
    return (
        <div className="p-relative card-container" style={{ height: height, width: width, background: background, justifyContent: `${center && 'center'}` }}>
            <img src={cardOverlay} alt="" className="card-overlay" />
            {children}
        </div>
    )
}

// if center, justify cent
import './registration_step.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

export default function RegistrationStep({ position, className, items }) {
    return (
        <div className={className || 'step-padding'}>
            <div className='d-flex j-space-between registration-step'>
                {
                    items.map(({ onClick }, index) => (
                        <button onClick={onClick} key={'stepper-' + index} className={`no-margin no-padding unstyle-button c-pointer ${index < items.length - 1 ? 'p-radial flex-one' : ''} p-radial-first d-flex align-center ${position > index ? 'active-line' : ''}`}>
                            <FontAwesomeIcon className={`radial ${position > index ? 'nav-item-color' : position === index ? 'active-border-color' : 'inactive-border-color'}`} icon={position > index ? faCheckCircle : faCircle} />
                        </button>
                    ))
                }

            </div>
            <div className='d-flex j-space-between font-size-17 registration-text'>
                {
                    items.map(({ name }, index) => (
                        <p key={'stepper-term-' + index} className={`no-margin ${position === index ? 'text-color fw400' : 'faded-text-color fw300'} ${index < items.length - 1 ? 'content-radial flex-one' : ''}`}>
                            {name}
                        </p>
                    ))
                }
            </div>
        </div>
    );
}
import Layout from '../../components/layout/layout';
import TermsContent from '../../components/terms_content/terms_content';
export default function Terms() {
    return (
        <Layout>
            <TermsContent />
        </Layout>
    );
}


import './home_app_area.css';
import mobileAppPortrait from '../../assets/images/hand-holding-phone.png';
import spiral from '../../assets/images/spiral.png';
import { Spacer } from '../layout/layout';
import AppButton from '../app_button/app_button';
import { useHistory } from 'react-router';


export default function HomeAppArea() {
    const history = useHistory()
    return (
        <div className='content-padding mobile-spacing home-app-area-container'>
            <div className='p-relative home-app-area'>
                <div className='home-app-text-area'>
                    <p className='fw500 font-size-bigger no-margin primary-color'>
                        All-in-one platform for business
                    </p>
                    <Spacer height={20} />
                    <p className='home-app-text fw400 font-size-20 primary-color'>
                        Sign up today and start receiving payments from over of
                        50% of your unstapped customer base, transaction notifications are instant.
                    </p>
                    <Spacer height={25} />
                    <AppButton isActive={true} name='Create free account' onClick={() => history.push('/signup')} className='header-account-button fw500 font-size-20' />
                </div>
                <>
                    <img className='p-absolute home-app-image no-mobile' src={mobileAppPortrait} alt='mobile app portrait view' />
                </>
                <img src={spiral} alt="" className='p-absolute spiral-rope' />
            </div>
            <Spacer height={70} />
        </div>
    );
}
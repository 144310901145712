import send from '../../assets/images/send.png'
import bill from '../../assets/images/bill.png'
import buy from '../../assets/images/buy.png'
import growth from '../../assets/images/growth.png'
import track from '../../assets/images/track.png'
import save from '../../assets/images/save.png'
import bvn_lookup from '../../assets/images/bvn-lookup.png'
import bvn_confirm from '../../assets/images/bvn-confirm.png'


export const FEATURES = [
    {
        image: send,
        title: 'Send & Receive Money',
        content: 'Send and receive money from / to any bank account in Nigeria'
    },
    {
        image: bill,
        title: 'Bill Payments',
        content: 'Pay for electricity, Internet, TV, other utilities conveniently'
    },
    {
        image: save,
        title: 'Save your Money',
        content: 'On the app and get interest on your savings'
    },
    {
        image: buy,
        title: 'Buy Airtime',
        content: ' MTN, GLO, 9mobile, Airtel, etc.'
    },
    {
        image: track,
        title: 'Track cashier transactions',
        content: ' Merchants can view all cashier(s) transactions realtime.'
    },
    {
        image: growth,
        title: 'Track business analysis/growth',
        content: ' Monitor business growth on the app in real time.'
    },
]

export const API_FEATURES = [
    {
        image: bvn_lookup,
        title: 'BVN Lookup',
        content: ' Use this API to lookup name and bio-data information for a given BVN'
    },
    {
        image: bvn_confirm,
        title: 'BVN Confirmation',
        content: 'Use this API to confirm the availability of BVN record for a customer.'
    },
    {
        image: bvn_lookup,
        title: 'Outbound Transfer',
        content: 'Use our API for bulk outward transfer'
    },
    {
        image: save,
        title: 'Receive Money',
        content: 'Our API can also be used to receive payments via wallet per customer.'
    },
]
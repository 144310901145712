import React from 'react'

import avatar from '../../../../assets/images/avatar.png';
import notificationIcon from '../../../../assets/images/notification.png'


import './cashpoint_dashboard.css'
import DashBoardCard from '../../../../components/dashboard_card/dashboard_card';
import { Spacer } from '../../../../components/layout/layout';
import { CASHPOINT_MAPPING } from '../../../../constants/title';
import { useTitleName } from '../../../../hooks/use_title';


const CashpointDashboard = () => {
    return (
        <DashboardLayout>
            <div className="top-cards-container">
                <Spacer height={50} />
                <div className="two-cards d-flex j-space-between">
                    <div className="d-flex align-center">
                        <DashBoardCard background="white" height="130px" width="360px" center>
                            <div className="d-flex align-center">
                                <div>
                                    <img src={avatar} alt="avatar" />
                                </div>
                                <Spacer width={20} />
                                <div className="d-flex flex-vertical">
                                    <h3 className="no-margin line-height12 fw700 font-size-14em">Flora Okoro</h3>
                                    <p className="no-margin fw400 font-size-1em">floralokoro@Vpay.cash</p>
                                </div>
                            </div>
                        </DashBoardCard >
                        <Spacer width={20} />
                        <DashBoardCard background="linear-gradient(91.66deg, #AB3882 9.63%, #ED2B30 76.56%, #F79420 110.1%)" height="130px" width="360px" center>
                            <p className="fw400 font-size-1em line-height17 no-margin primary-color">Transactions</p>
                            <h3 className="no-margin fw700 font-size-14em primary-color">N345,000.00</h3>
                        </DashBoardCard>
                    </div>
                    <h3 className="no-margin fw700 line-height17 generate-payment primary-color c-pointer">GENERATE PAYMENT</h3>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default CashpointDashboard;

export const NotificationCard = ({ borderRadius }) => (
    <div style={{ borderRadius: borderRadius || 5 }} className="notify-card c-pointer">
        <div>
            <img src={notificationIcon} alt="" />
        </div>
        <Spacer width={6} />
        <span className="fw500 primary-color">15</span>
    </div>
)


export const DashboardHeader = ({ children, backgroundColor, titleMap }) => {
    const titleName = useTitleName(titleMap)
    return (
        <div className="d-flex dashboard-header full-width" style={{ backgroundColor }}>
            <h2 className="no-margin fw700 text-color font-size-22">{titleName}</h2>
            <div>
                {children}
            </div>
        </div>
    )
}

const CashpointHeader = () =>
(
    <DashboardHeader titleMap={CASHPOINT_MAPPING}>
        <NotificationCard />
    </DashboardHeader>
)

/**
 * 
 * @param {ReactNode} header - Specifies the nav
 * 
 */
export const DashboardLayout = ({ children, header }) => {
    const defaultHeader = header || <CashpointHeader />;
    return (

        <div className="p-relative dashboard-layout full-height">
            {defaultHeader}
            <div className="full-width p-relative" style={{ padding: '0px 40px' }}>
                {children}
            </div>
        </div>
    )
}


import './app_button.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default function AppButton({ name, className, onClick, isActive, type, isBusy, color, backgroundColor }) {
    return (
        <button style={{ color, backgroundColor }} className={`app-button ${className || ''}`} onClick={(isActive && !isBusy) ? onClick : null} type={type || 'button'}>
            {isBusy ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : name}
        </button>
    );
}


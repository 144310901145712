import { useEffect } from "react";

export default function usePageLayout() {
    let viewport_meta = document.querySelector('meta[name="viewport"]')
    let viewports = {
        default: viewport_meta.getAttribute('content'),
        landscape: 'width=1600'
    }
    useEffect(() => {
        if (window.screen.width > 800) {
            viewport_meta.setAttribute('content', viewports.landscape);
        } else {
            viewport_meta.setAttribute('content', viewports.default);
        }
        return () => viewport_meta.setAttribute('content', viewports.default);
    }, [window.screen.width])
}
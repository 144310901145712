import { DashboardLayout } from "../cashpoint_dashboard/cashpoint_dashboard";

const CashpointSupport = () => {
    return (
        <DashboardLayout>
            <div>
                Support Yeeee!!! Message mee!!!!
            </div>
        </DashboardLayout>
    )
}

export default CashpointSupport;
import './image_upload.css';

export default function ImageUpload({ fileProps, className, src, clickable, imgText }) {

    return (
        <div className={`${className || ''} image-upload-area d-flex align-center`}>
            <CustomInputFile clickable={clickable} fileProps={fileProps} className='j-center'>
                <img src={src} alt='user profile selector' />
            </CustomInputFile>
            <p className='no-margin text-bold primary-color img-text-upload'>{imgText || 'Upload profile picture'}</p>

        </div>
    );
}


export const CustomInputFile = ({ className, fileProps, children, clickable }) => {
    const { src = null } = fileProps || {};
    return (
        <div className={`clickable-area d-flex p-relative align-center ${className || ''}`} style={{ backgroundImage: `url(${src || ''})` }}>
            {!src && children}
            {clickable && <input type='file' className='custom-file-input p-absolute' {...fileProps} />}
        </div>
    );
}
import { useState } from "react";
import AppButton from "../../components/app_button/app_button";
import { FormInput, SearchIcon } from "../../components/form_input/form_input";
import { Spacer } from "../../components/layout/layout";
import { DocumentUpload, RegistrationContainer, TwoRowText } from "../../components/merchant_widgets/merchant_widgets";
import StepperLayout, { RegistrationForm } from "../../components/stepper_layout/stepper_layout"

const detailSpacer = <Spacer height={30} />
const formSpacer = <Spacer height={10} />
export default function MerchantCompliance() {
    const [position, setPosition] = useState(0)
    const items = [
        { name: 'profile', onClick: () => setPosition(0) },
        { name: 'contact', onClick: () => setPosition(1) },
        { name: 'account', onClick: () => setPosition(2) },
        { name: 'document', onClick: () => setPosition(3) },
    ];

    const getDetail = () => {
        switch (position) {
            case 0:
                return <ProfileDetail />
            case 1:
                return <ContactDetail />
            case 2:
                return <AccountDetail />
            case 3:
                return <DocumentDetail />
            default:
                return null;
        }
    }
    return (
        <RegistrationContainer>
            <StepperLayout items={items} position={position}>
                <RegistrationForm position={position} total={items.length}
                    onNext={() => setPosition(() => position + 1)} hideButtonWhen={position > 1}>
                    {getDetail()}
                </RegistrationForm>
                {
                    position > 1 &&
                    <>
                        <Spacer height={30} />
                        <AppButton isActive name='Save'
                            className='action-color-2 full-width primary-color font-size-17 mb-30'
                            type="button" />
                    </>
                }
            </StepperLayout>
        </RegistrationContainer>
    );
}

function ProfileDetail({ merchant }) {
    return (
        <div>
            <TwoRowText title='business owner' description={merchant?.name ?? 'Mr. Okoro Frank'} />
            {detailSpacer}
            <TwoRowText title='legal business name' description={merchant?.businessName ?? 'QMart Stores Limited'} />
            {detailSpacer}
            <TwoRowText title='business industry' description={merchant?.industry ?? 'Hospitality'} />
        </div>
    );
}

function AccountDetail({ merchant }) {
    return (
        <form className='account-detail'>
            <p className='fw500 text-color font-size-17 text-capitalize'>enter the business bank account</p>
            <Spacer height={20} />
            <FormInput label="Account Provider" icon={<SearchIcon className='input-icon' />} />
            {formSpacer}
            <FormInput label="Account Number" />
            {formSpacer}
            <FormInput label="Account Name" />
        </form>
    );
}

function DocumentDetail({ merchant }) {
    return (
        <form className='account-detail'>
            <p className='fw500 text-color font-size-17 text-capitalize'>document</p>
            <Spacer height={10} />
            <p className='fw300 faded-text-color font-size-17 text-capitalize'>please upload all documents</p>
            <Spacer height={30} />
            <DocumentUpload text='Certificate of Business Name' clickable />
            <Spacer height={10} />
            <DocumentUpload text='Bank Verification Number (BVN)' clickable />
            <Spacer height={10} />
            <DocumentUpload text='Proof of Address' clickable />
        </form>
    );
}

function ContactDetail({ merchant }) {
    return (
        <div>
            <TwoRowText title='business email' description={merchant?.email ?? 'qmartstores@gmail.com'} />
            {detailSpacer}
            <TwoRowText title='phone number' description={merchant?.phone ?? '0706739267391'} />
            {detailSpacer}
            <TwoRowText title='business address' description={merchant?.address ?? 'John Fashanu gardens Sun city, lokogoma Abuja, Federal Capital Territory Nigeria'} />
        </div>
    );
}


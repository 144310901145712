// import ImageBackground from '../image_background/image_background';
import peterOlexa from '../../assets/images/peterOlexa.png';
import './home_about.css';
// import CircleAvatar from '../circle_avatar/circle_avatar';
// import colors from '../../utils/color/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { Spacer } from '../layout/layout';
import { Link } from 'react-router-dom';

export default function HomeAbout() {
    return (
        <div className='content-padding mobile-spacing home-about flex-vertical-mobile d-flex'>
            <div className='p-relative home-about-left d-flex align-center j-center'>
                <img src={peterOlexa} alt='Peter Olexa using vpay' />
                <button type='button' className='unstyle-button p-absolute c-pointer play-button'>
                    <FontAwesomeIcon className='play' icon={faPlay} />
                </button>
            </div>

            <Spacer width={60} />
            <div className='d-flex flex-vertical home-about-right j-center'>
                <h2 className='fw500 font-size-bigger line-height12 brown-text no-margin'>Vpay is about more than Selling</h2>
                <Spacer height={20} />
                <p className='home-about-text font-size-20 fw400 line-height17' style={{ maxWidth: "600px" }}>
                    VPay is a payment gateway that allows
                    cashiers and online shopping carts to accept
                    payments via bank transfer and instantly confirm
                    these payments without depending on the
                    merchants (business owner or accountant.
                    <br />
                    <br />
                    Our mission is to simplify the bank
                    transfer option for merchants and their
                    customers when shopping in Africa
                </p>
            </div>
        </div>
    );
}
import { Route, Switch } from "react-router-dom";
import { ScrollToTop } from "./components/scroll_to_top/scroll_to_top";
import About from "./pages/about/about";
import Contact from "./pages/contact/contact";
import Home from "./pages/home/home";
import Terms from "./pages/terms/terms";
import Login from './pages/login/login'
import PrimarySignUp from './pages/primary_signup/primary_signup'
import SecondarySignUp from './pages/secondary_signup/secondary_signup'
import PasswordRequest from './pages/password_request/password_request'
import VerifyAccount from './pages/verify_account/verify_account'
import MerchantPage from "./pages/merchant/merchant";
import CaspointParent from "./pages/cashpoint_parent/cashpoint_parent";
import CashierPage from "./pages/cashier_page/cashier";
import DeveloperPage from "./pages/developer/developer";
import useTitle from "./hooks/use_title";
import { TITLE, TITLE_MAPPING } from "./constants/title";
import { MerchantDashboard } from "./components/merchant_widgets/merchant_widgets";
import CashierDashboardWrapper from "./pages/cashpoint_parent/cashpoint_desktop/cashpoint_wrapper/cashpoint_wrapper";

export default function App() {
  useTitle(TITLE, TITLE_MAPPING);
  return (
    <ScrollToTop>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/about' component={About} />
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/terms-and-condition' component={Terms} />
        <Route exact path='/signup' component={PrimarySignUp} />
        <Route exact path='/more-details' component={SecondarySignUp} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/forgot-password' component={PasswordRequest} />
        <Route exact path='/verify-account' component={VerifyAccount} />
        <Route exact path='/merchant-page' component={MerchantPage} />
        <Route path='/merchant' component={MerchantDashboard} />
        <Route path='/cashier-dashboard' component={CashierDashboardWrapper} />
        <Route exact path='/cashier-page' component={CashierPage} />
        <Route exact path='/developer-page' component={DeveloperPage} />
        <Route path='/cashier-dashboard' component={CaspointParent} />
      </Switch>
    </ScrollToTop>
  );
}


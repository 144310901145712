import { Link } from 'react-router-dom';
import { Spacer } from '../layout/layout';
import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faLinkedinIn, faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import AppDivider from '../app_divider/app_divider';
import colors from '../../utils/color/colors';

export default function Footer() {
    const { textColor } = colors();
    return (
        <footer className='content-padding'>
            <FooterLinks />
            <Spacer height={40} className='no-mobile' />
            <AppDivider />
            <Spacer height={25} />
            <p style={{ color: "#FFFFFF"/*textColor.withOpacity(0.5)*/ }} className='text-justify text-center-mobile fw400 font-size-small'>
                VPay is a property of Minerva Technology Limited,
                a company duly registered with CAC with RC Number: 3333333.
                VPay.cash provides financial services under a Cooperative
                License with a registration number 99999.
                Both VPay and Minerva Technology Limited are legal entities in Nigeria.
                ligula ut lacinia viverra, lorem lacus condimentum leo, eu luctus dolor ex at quam.
                Fusce a nisi at erat dapibus posuere eget sed nulla. Nam sem odio, hendrerit vel mi ut,
                pharetra viverra massa. Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                per inceptos himenaeos. Nunc placerat ante vel eros semper bibendum. Donec ultricies vestibulum interdum.
            </p>
            <Spacer height={10} />
            <SocialLinks className='no-desktop-but-mobile-flex' />
            <Spacer height={20} className='no-desktop-but-mobile' />
            <p style={{ color: "#FFFFFF" /*textColor.withOpacity(0.5)*/ }} className='text-justify text-center-mobile fw400 font-size-little'>
                &copy; 2021 VPay. All rights reserved
            </p>
        </footer>
    );
}


const FooterLinks = () => {
    return (
        <div className='d-flex align-center flex-vertical-mobile j-space-between'>
            <ul className='unstyle-ul d-flex vpay-links flex-vertical-mobile'>
                <li className='footer-links fw500'><Link to='/about'>About Vpay</Link></li>
                <Spacer width={40} />
                <li className='footer-links fw500'><Link to='/terms-and-condition'>Terms of service</Link></li>
                <Spacer width={40} />
                <li className='footer-links fw500'><Link to='/'>Privacy policy</Link></li>
                <Spacer width={40} />
                <li className='footer-links fw500'><Link to='/contact'>Contact</Link></li>
            </ul>
            <SocialLinks className='no-mobile' />
        </div>

    );
}

const SocialLinks = ({ className }) => {
    return (
        <ul className={(className || '') + ' unstyle-ul d-flex j-center'}>
            <li className='social-links fw500'>
                <Link to='/login'>
                    <FontAwesomeIcon className='footer-social-links' icon={faFacebookSquare} />
                </Link>
            </li>
            <Spacer width={20} />
            <li className='social-links fw500'>
                <Link to='/login'>
                    <FontAwesomeIcon className='footer-social-links' icon={faInstagram} />
                </Link>
            </li>
            <Spacer width={20} />
            <li className='social-links fw500'>
                <Link to='/login'>
                    <FontAwesomeIcon className='footer-social-links' icon={faTwitter} />
                </Link>
            </li>
            <Spacer width={20} />
            <li className='social-links fw500'>
                <Link to='/login'>
                    <FontAwesomeIcon className='footer-social-links' icon={faLinkedinIn} />
                </Link>
            </li>
        </ul>

    );
}
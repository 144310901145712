import { KeyFeatures } from '../home_feature/home_feature';
import './merchant_info.css'

import merchant_pressing_phone from '../../assets/images/merchant-pressing-phone.png'

import grid11 from '../../assets/images/grid11.png'
import grid12 from '../../assets/images/grid12.png'
import grid13 from '../../assets/images/grid13.png'
import grid14 from '../../assets/images/grid14.png'
import { Spacer } from '../layout/layout'

const contentOne = [
    "POS Agent ",
    "Retail stores",
    "Supermarket",
    "Pharmacy",
    "Restaurants",
    "Bars, and Hotels"
]

const contentTwo = [
    "Retail stores",
    "Petrol station",
    "Schools",
    "Airline booking site",
    "Ecommerce",
    "Schools etc."
]

const MerchantInfo = () => {
    return (
        <div className="full-height full-width head-padding-body d-flex j-center">
            <div className="d-grid info-container align-center">
                <div className="p-relative full-width full-height">
                    <Spacer height={80} />
                    <h1 className="no-margin font-size-35 text-color fw500">Who should get Vpay<br /> for Merchants</h1>
                    <Spacer height={30} />
                    <p className="fw400 font-size-17 text-color no-margin">
                        Payment via cash and card is the norm today. Our<br /> aim with
                        Vpay is to make payment via transfer, the<br /> number one
                        channel for payments when shopping.
                    </p>
                    <Spacer height={45} />
                    <div className="k-group" style={{ maxWidth: "500px" }}>
                        <div className="p-relative">
                            <KeyFeatures content={contentOne} />
                        </div>
                        <div className="p-relative">
                            <KeyFeatures content={contentTwo} />
                        </div>
                    </div>
                </div>
                <div>
                    <img src={merchant_pressing_phone} alt="merchant pressing phone" />
                </div>
            </div>
        </div>
    )
}

export const ImageGrid = () => (
    <div className="d-grid full-width info-container w-grid">
        <div className="full width d-grid image-grid-container">
            <img src={grid11} alt="" className=" full-width" />
            <img src={grid12} alt="" className="grid12" />
        </div>
        <div className="full-width d-grid image-grid-container">
            <img src={grid13} alt="" className=" grid13" />
            <img src={grid14} alt="" className="full-width" />
        </div>
    </div>
)

export default MerchantInfo

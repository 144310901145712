import { Select } from 'antd'
import 'antd/dist/antd.css';
import './form_dropdown.css'


export function FormDropdown({ options, label, className, loading }) {
    return (
        <div className="dropdown-container p-relative">
            {
                label && <span className="dropdown-label fw200 ">{label}</span>
            }
            <div className="p-relative">
                <Select
                    showSearch
                    className={className}
                    bordered={false}
                    size='large'
                    loading={loading}
                    autoComplete="new-password"
                >
                    {options?.map((option, idx) => <Select.Option value={option} key={idx}>{option}</Select.Option>)}
                </Select>
            </div>
        </div>
    )
}

import { FeatureContent, KeyFeatures } from '../home_feature/home_feature';
import { Spacer } from '../layout/layout';
import './about_feature.css';
import clockIcon from '../../assets/images/clockIcon.svg';
import exclamationIcon from '../../assets/images/exclamationIcon.svg';
import vpayAsV from '../../assets/images/vpayAsV.png';


const kFeatures = [
    'Convallis turpis erat tempus',
    'Nullam viverra nam auctor sit ipsum',
    'Convallis turpis erat tempus',
    'Nullam viverra nam auctor sit ipsum'
]
export default function AboutFeature() {
    return (
        <div className='content-padding mobile-spacing'>
            <div className=''>
                <p className='font-size-small letter-spacing1'>VPAY FEATURES</p>
                <Spacer height={20} />
                <p className='fw700 font-size-bigger line-height12'>
                    Convallis turpis erat tempus,<br /> viverra aliquet Convallis turpis
                    </p>
                <Spacer height={20} />
                <p className='feature-text about-feature-text line-height17 half-width'>
                    Convallis turpis erat tempus, viverra aliquet.
                    Nullam viverra nam auctor sit ipsum malesuada a, duis volutpat.
                    Convallis turpis erat tempus, viverra aliquet.
                    Nullam viverra nam auctor sit ipsum malesuada a, duis volutpat.
                    </p>
                <Spacer height={40} />
                <div className='d-flex flex-vertical-mobile'>
                    <div>
                        <FeatureContent icon={exclamationIcon} title='New Customers'
                            description='Reach out to a large audience of Vpay customers and shoppers who don’t pay with bank cards.' />
                        <Spacer height={20} />
                        <FeatureContent icon={clockIcon} title='Convallis Turpis Erat'
                            description='Vitae nulla euismod velpretium tellus accumsan nulla nunc euismod ve semper.' />
                    </div>
                    <Spacer width={40} />
                    <div className='top-margin-mobile-20'>
                        <FeatureContent icon={clockIcon} title='Increased Purchases'
                            description='Attract a greater number of shoppers who are comfortable with making payments via bank transfers.' />
                        <Spacer height={20} />
                        <FeatureContent icon={exclamationIcon} title='Zero Risk'
                            description='We assume all risk. You get paid in full, immediately a customer’s bank transfer occurs.' />
                    </div>
                </div>

                <Spacer height={150} className='no-mobile' />

                <div className='more-about-feature'>
                    <div className='d-flex flex-vertical-mobile j-space-between'>
                        <div className='half-width d-flex align-center about-feature-left'>
                            <img src={vpayAsV} className='vpay-v-image full-width' alt='VPAY as a V shape' />
                        </div>
                        <Spacer width={50} />
                        <div className='about-feature-right'>
                            <p className='fw700 font-size-bigger line-height12'>
                                Requirements gathering<br />and analysis for references
                            </p>
                            <Spacer height={30} />
                            <p className='feature-text line-height17 text-justify-mobile'>
                                Convallis turpis erat tempus, viverra aliquet.
                                Nullam viverra nam auctor sit ipsum malesuada a, duis volutpat.
                                Convallis turpis erat tempus, viverra aliquet.
                                Nullam viverra nam auctor sit ipsum malesuada a, duis volutpat.
                            </p>
                            <Spacer height={25} />
                            <KeyFeatures content={kFeatures}/>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
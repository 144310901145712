import './terms_content.css'
import termsContent from './terms.json';
import { Spacer } from '../layout/layout';
import { ContentPadding, GradientBackground } from '../contact_content/contact_content';

export default function TermsContent(params) {
    return (
        <div style={{ padding: "120px 0px" }}>
            <GradientBackground>
                <PageHeader text='Vpay terms and conditions' />
            </GradientBackground>
            <ContentPadding>
                <Spacer height={20} />
                <div dangerouslySetInnerHTML={{ __html: termsContent.content }}></div>
            </ContentPadding>
        </div>
    );
}

export const PageHeader = ({ text }) => {
    return (
        <h1 className='fw700 font-size-large primary-color p-sticky page-header no-margin'>{text}</h1>
    );
}
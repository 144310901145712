export const industries = [
        "Advertising Agencies",
        "Airlines",
        "Apparel Retailers",
        "Automobile/Auto Part Retailers",
        "Automobiles/Auto Parts",
        "Banking",
        "Beverages--Brewers/Distillers",
        "Beverages--Non-Alcoholic",
        "Building Construction",
        "Building Materials",
        "Building Structure/Completion",
        "Chemicals",
        "Coal Extraction",
        "Coal and Coal Products Distributors",
        "Computer Based Systems",
        "Computer Software",
        "Computers and Peripherals",
        "Consumer Electronics",
        "Cosmetics",
        "Courier/Freight/Delivery",
        "Crop Production",
        "Crude Oil and Natural Gas Extraction",
        "Diversified Communication Services",
        "Education/Training",
        "Electric Power Distribution",
        "Electric Power Generation",
        "Electric Power Transmission",
        "Electronic Office Equipment",
        "Electronic and Electrical Products",
        "Electronics/Appliances Retailers",
        "Employment Solutions",
        "Energy Equipment and Services",
        "Fashion/Clothing",
        "Field Services",
        "Fishing/Hunting/Trapping",
        "Food Products",
        "Food Products--Diversified",
        "Food/Drug Retailers and Wholesalers",
        "Gasoline Stations",
        "Healthcare Providers",
        "Hospitality",
        "Hotels/Lodging",
        "Household Durables",
        "IT Services",
        "Insurance Carriers, Brokers and Services",
        "Integrated Oil and Gas Services",
        "Internet Service Providers",
        "Livestock/Animal Specialties",
        "Media/Entertainment",
        "Medical Equipment",
        "Medical Supplies",
        "Metals",
        "Mining Services",
        "Miscellaneous Services",
        "Mortgage Carriers, Brokers and Services",
        "Non-Building/Heavy Construction",
        "Non-Depository Credit Institutions",
        "Non-Metallic Mineral Mining",
        "Other Financial Institutions",
        "Other ICT Products and Services",
        "Other Services",
        "Packaging/Containers",
        "Paper/Forest Products",
        "Personal/Household Products",
        "Petroleum Bulk Stations and Terminals",
        "Petroleum Refining",
        "Petroleum and Petroleum Products Distributors",
        "Pharmaceuticals",
        "Precious Metals",
        "Precious Stones",
        "Printing/Publishing",
        "Processing Systems",
        "Property Management",
        "Rail Transportation",
        "Real Estate Development",
        "Real Estate Investment Trusts(REITs)",
        "Repair/Maintenance",
        "Road Transportation",
        "Scientific and Technical Instruments",
        "Semiconductors",
        "Site Preparation Services",
        "Specialty Retailers",
        "Storage/Warehousing",
        "Telecommunications Carriers",
        "Telecommunications Equipment",
        "Telecommunications Services",
        "Textiles/Apparel",
        "Tobacco Products",
        "Tools and Machinery",
        "Toys and Games",
        "Transport-Related Services",
        "Travel and Tourism",
        "Waste Management",
        "Water Transportation",
        "Water Treatment and Distribution",
]
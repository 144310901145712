import React from 'react'
import { AboutBody } from '../merchant_about/merchant_about'

import smiling_cashier from '../../assets/images/smiling-cashier.png'
import full_dasboard_with_iPhone from '../../assets/images/full-dasboard-with-iPhone.png'
import stroke from '../../assets/images/stroke.svg'
import { Spacer } from '../layout/layout'

const content = [
    {
        title: 'Payment Verification',
        content: <>Cashier can instantly verify payment<br /> without depending on the business owner</>,
    },
    {
        title: 'Fake Alert Elimination',
        content: <>Eliminate risk of bank transfer reversal<b /> and customer fraud.</>,
    },
    {
        title: 'Fast Payment Verification',
        content: <>Give customer immediate verification,<br /> eliminating customers wait time.</>,
    },
]

export default function CashierAbout() {
    return (
        <>
            <AboutBody image={smiling_cashier}>
                <h1 className="fw500 font-size-30 text-color">
                    Why you should get<br /> Vpay for Cashiers
           </h1>
                <Spacer height={30} />
                <Points content={content} />
            </AboutBody>
            <Spacer height={150} />
            <div className="small-content-padding">
                <img src={full_dasboard_with_iPhone} className="full-width" alt="vpay desktop app" />
            </div>
        </>
    )
}


export const Points = ({ content }) => (
    <>
        {
            content.map((item, idx) => (
                <div className="full-width d-flex align-start max-width500" key={idx}>
                    <div>
                        <Spacer height={10} />
                        <img src={stroke} alt="" />
                    </div>
                    <Spacer width={30} />
                    <div>
                        <p className="fw500 text-color font-size-22 line-height17 no-margin">{item.title}</p>
                        <Spacer height={5} />
                        <span className="20fw400 text-color font-size-20">{item.content}</span>
                        <Spacer height={40} />
                    </div>
                </div>
            ))
        }
    </>
)
import { Link, useLocation } from 'react-router-dom';

import cashier_logo from '../../assets/images/cashier-logo.png';

import { Spacer } from '../layout/layout';
import DashBoardCard from '../dashboard_card/dashboard_card';
import './app_nav.css'

const BOTTOM_CARD_TEXT = 'Invite customers to transfer using Vpay and Earn 15% of the transaction '

/**
 * 
 * @param {string} background - Specifies the nav background
 * @param {boolean} isMerchant - Specifies  merchant, therefore change color
 * @param {Object} links - Specifies link object check title.js
 * @param {Array} navKeys - Specifies keys for the link object
 * 
 */

const AppNav = ({ links, navKeys, beforeNavWidget }) => {

    const location = useLocation();
    const isMerchantActiveLinkTitle = (link) => {
        if (link === location.pathname) {
            return "cashier-active-link"
        }
    }

    return (
        <div className={`p-relative d-flex cashier-nav-wrapper`}>
            <div className="p-relative">
                <img src={cashier_logo} alt="cashier app logo" />
            </div>
            {
                beforeNavWidget ? beforeNavWidget : <Spacer height={100} />
            }

            <div className="d-flex flex-vertical j-space-between full-height">
                <div className="nav-links-wrapper">
                    {
                        navKeys?.map((navKey, idx) => {
                            return (
                                <div key={idx}>
                                    <Link to={navKey} className={'cashier-link-item-wrapper d-flex align-center'}>
                                        <span dangerouslySetInnerHTML={{ __html: links[navKey]?.icon }} className={`cashier-link-title  ${isMerchantActiveLinkTitle(navKey)} d-flex align-center`}></span>
                                        <Spacer width={20} />
                                        <span className={`cashier-link-title font-size-17  ${isMerchantActiveLinkTitle(navKey)}`}>{links[navKey]?.title}</span>
                                    </Link>
                                    {idx < navKeys.length - 1 && <Spacer height={30} />}
                                </div>
                            )
                        })
                    }
                </div>
                <Spacer height={40} />
                <BottomCard />

            </div>
        </div>

    )
}



export const BottomCard = () => (
    <div className="bottom-card-wrapper">
        <DashBoardCard
            background="linear-gradient(91.66deg, #AB3882 9.63%, #ED2B30 76.56%, #F79420 110.1%)"
            width={200}
            height={180}
        >
            <p className="fw700 primary-color font-size-16 no-margin">{BOTTOM_CARD_TEXT}</p>
            <Spacer height={10} />
            <span className="bottom-card-btn c-pointer fw700 primary-color font-size-16 no-margin full-width d-flex align-center line-height17">Invite customer</span>
        </DashBoardCard>
    </div>
)

export default AppNav;
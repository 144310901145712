import { useHistory } from "react-router";
import { FormInput } from "../../components/form_input/form_input";
import { ColouredBackground, FormLayout } from "../../components/layout/layout";


export default function Login() {
    const history = useHistory()
    return (
        <ColouredBackground
            bottomText="Don’t have an account?"
            linkText="Signup"
            lastText="Forgot your password?"
            route="/signup"
            onClick={() => history.push('/forgot-password')}
        >
            <FormLayout
                headerText="login to your account"
                buttonText="Login To My Account"
                onClick={() => history.push('/')}
            >
                <FormInput label="Email Address" />
                <FormInput label="Password" />
            </FormLayout>
        </ColouredBackground >
    )
}

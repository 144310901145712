import { ColouredBackground, FormLayout } from "../../components/layout/layout";


export default function VerifyAccount() {

    return (
        <ColouredBackground
            bottomText="Didn't get any email?"
            linkText="Resend Mail"
            route="/login"
        >
            <FormLayout
                headerText="verify account"
                buttonText="Check Mailbox"
            >
                <ContentMessage />
            </FormLayout>
        </ColouredBackground>
    )
}

export const ContentMessage = () => (
    <p className="font-size-20">
        We Have Sent An Email To ************************ With A Link To  Reset Your Password
        <br />
        <br />
        If You Have Not Received A Mail After A Few Minutes, Please Check  Your Spam Folder.
    </p>
)
import { useHistory } from 'react-router';
import AppButton from '../app_button/app_button';
import { Spacer } from '../layout/layout';
import './merchant_header.css'

import merchant_lady from '../../assets/images/merchant-lady.png'

const title = "Vpay for Merchants"
const content = <>Provides everything you need in the form of web or mobile<br /> apps to run and grow your retail business by the<br /> automation bank transfer</>

export default function MerchantHeader() {
    const history = useHistory()
    return (
        <HeaderImage background={merchant_lady}>
            <HeadContentContainer title={title} content={content}>
                <AppButton className="merchant-header-btn-width header-account-button font-size-20" isActive={true} name='Get Started' onClick={() => history.push('/signup')} />
            </HeadContentContainer>
        </HeaderImage>
    )
}

export const HeadContentContainer = ({ title, content, children }) => {
    return (
        <div className="head-padding">
            <div className="d-flex flex-one flex-vertical ">
                <h1 className="fw500 font-size-50 text-color no-margin">{title}</h1>
                <Spacer height={20} />
                <p className="fw400 text-color no-margin font-size-20">{content}</p>
                <Spacer height={20} />
                {children}
            </div>
        </div>
    )
}

export const HeaderImage = ({ children, background }) => {
    return (
        <div className='image-header  p-relative' style={{ backgroundImage: `url(${background})` }}>
            <div className='full-height full-width rec-overlay d-flex flex-1 flex-vertical j-center'>
                {children}
            </div>
        </div>
    );
}
import HomeHeader from "../../components/home_header/home_header";
import HomeAbout from "../../components/home_about/home_about";
import HomeAppArea from "../../components/home_app_area/home_app_area";
import HomeFeature, { HomeInfo } from "../../components/home_feature/home_feature";
import Layout, { Spacer } from "../../components/layout/layout";

export default function Home() {
    return (
        <div>
            <Layout>
                <HomeHeader />
                <Spacer height={80} className='no-mobile' />
                <HomeAbout />
                <Spacer height={150} className='no-mobile' />
                <HomeFeature />
                <PinkGradient>
                    <Spacer height={80} />
                    <HomeInfo />
                    <Spacer height={150} className='no-mobile' />
                    <HomeAppArea />
                </PinkGradient>
            </Layout>
        </div>
    );
}

export const PinkGradient = ({ children }) => (
    <div className="pink-gradient-background">
        {children}
    </div>
)
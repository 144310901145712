import { Link } from 'react-router-dom'
import AppButton from "../app_button/app_button";
import AppIcon from "../app_icon/app_icon";
import Footer from "../footer/footer";
import Navigation from "../navigation/navigation";

export default function Layout({ children }) {
    return (
        <div className='p-relative full-height'>
            <Navigation />
            <div className='d-flex full-height flex-vertical'>
                <div className='flex-one full-height'>
                    {children}
                </div>
                <Footer />
            </div>

        </div>

    );
}


export const Spacer = ({ width, height, className }) => (<div style={{ width, height }} className={className || ''}></div>)

export const ColouredBackground = ({ children, bottomText, route, linkText, lastText, onClick }) => (
    <div className="full-width full-vh overflow-auto page-color-background d-flex flex-vertical align-center app-padding-starter-tb">
        <div className='mb-30'><AppIcon height={45} /></div>
        {children}
        {
            bottomText && (
                <>
                    <span className="font-size-17 fw400 text-center mt30 ">{bottomText} <Link to={route || '/'} className='action-color text-dec-none ml5 font-size-17'>{linkText}</Link></span>
                    <span onClick={onClick} className="font-size-17 fw400 text-center c-pointer">{lastText}</span>
                </>
            )
        }
    </div>
)

export const FormContainer = ({ children, className }) => (
    <div className={(className || '') + " border-radius5  app-padding-starter-tb form-padding-lr primary-bg-color d-flex flex-vertical align-center"} >
        {children}
    </div>
)

export const FormLayout = ({ children, headerText, buttonText, bottomText, onClick, isBusy }) => (
    <FormContainer className='form-layout-width'>
        <p className="text-uppercase letter-spacing-em mt-20 font-size-clamp header-color mb-50" >{headerText}</p>
        {children}
        <AppButton isActive={true} name={buttonText} isBusy={isBusy} onClick={onClick} className='action-color-2 form-btn-width primary-color h-60 font-size-20 mb-30' type="submit" height={51} />
        {
            bottomText && (
                <p className="font-size-16 fw400 text-center">{bottomText}</p>
            )
        }
    </FormContainer>
)
import { Route, Switch } from "react-router-dom";
import CashpointSettings from "../cashpoint_settings/cashpoint_settings";
import CashpointSupport from "../cashpoint_support/cashpoint_support";
import CashpointTransactions from "../cashpoint_transactions/cashpoint_transactions";
import AppNav from "../../../../components/app_nav/app_nav";
import { getUsableNavLink } from "../../../../constants/title";
import useOverflow from "../../../../hooks/use_overflow";
import usePageLayout from "../../../../hooks/use_page_layout";
import CashpointDashboard from "../cashpoint_dashboard/cashpoint_dashboard";

const CashierDashboardWrapper = ({ match }) => {
    const { links, keys } = getUsableNavLink(2);
    return (
        <DashBoardWrapper>
            <AppNav links={links} navKeys={keys} />
            <Switch>
                <Route exact path={`${match.path}`} component={CashpointDashboard} />
                <Route exact path={`${match.path}/transactions`} component={CashpointTransactions} />
                <Route exact path={`${match.path}/support`} component={CashpointSupport} />
                <Route exact path={`${match.path}/settings`} component={CashpointSettings} />
            </Switch>
        </DashBoardWrapper>
    )
}

export default CashierDashboardWrapper

export function DashBoardWrapper({ children, background }) {
    usePageLayout();
    useOverflow();
    return (
        <div style={{ background: (background || '#E5E5E5'), display: 'flex', flexDirection: 'row' }} className="full-width full-vh">
            {children}
        </div>
    );
}
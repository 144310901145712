import { FormInput } from "../../components/form_input/form_input";
import { ColouredBackground, FormLayout } from "../../components/layout/layout";


export default function PasswordRequest() {

    return (
        <ColouredBackground
            bottomText="Remember your password? "
            linkText="Login"
            route="/login"
        >
            <FormLayout
                headerText="REQUEST PASSWORD RESET"
                buttonText="Request Reset"
            >
                <FormInput label="Email Address" />
            </FormLayout>
        </ColouredBackground>
    )
}

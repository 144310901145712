import { Switch, Route, useRouteMatch } from 'react-router-dom'
import MobileDashboard from '../mobile_dasboard/mobile_dasboard'

import './mobile_wrapper.css'

export default function Mobile() {
    const match = useRouteMatch()
    return (
        <div className="mobile-wrapper full-width full-vh">
            <Switch>
                <Route exact path={`${match.path}/`} component={MobileDashboard} />
            </Switch>
        </div>
    )
}

import { KeyFeatures } from "../home_feature/home_feature";
import { Spacer } from "../layout/layout";

import cashier_transacting from '../../assets/images/cashier-tranzacting.png'

const content = [
    "Reconcile Payment",
    "Instant Settlements",
    "Shop via bank transfer",
    "Increase customer sales"
]

const CashierAppArea = () => {
    return (
        <div className="full-height full-width small-content-padding">
            <div className="d-grid info-container align-center">
                <div className="p-relative full-width full-height">
                    <Spacer height={80} />
                    <h1 className="no-margin font-size-35 text-color fw500">Manage all transactions<br />in a single place</h1>
                    <Spacer height={30} />
                    <p className="fw400 font-size-20 text-color no-margin">
                        Payment via cash and card is the norm today. Our aim<br /> with
                        Vpay is to make payment via transfer, the number<br /> one
                        channel for payments when shopping.
                    </p>
                    <Spacer height={45} />
                    <div className="k-group">
                        <div className="p-relative">
                            <KeyFeatures content={content} />
                        </div>
                    </div>
                </div>
                <div>
                    <img src={cashier_transacting} alt="cashier transacting" />
                </div>
            </div>
        </div>
    )
}

export default CashierAppArea;
import ContactContent from "../../components/contact_content/contact_content";
import Layout from "../../components/layout/layout";

export default function Contact() {
    return (
        <Layout>
            <ContactContent />
        </Layout>
    );
}




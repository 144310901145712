import merchant_smiling from '../../assets/images/merchant-smiling.png';
import { KeyFeatures } from '../home_feature/home_feature';
import { Spacer } from '../layout/layout';

const kFeatures = [
    'Reconcile payment',
    'Instant settlements',
    'Shop via bank transfer',
    'Increase customer sales',
    'Eliminate customer delay',
    'Track sales'
]

const MerchantAbout = () => {
    return (
        <AboutBody image={merchant_smiling}>
            <h1 className="fw500 font-size-30 text-color">
                All-in-one payment solution<br />
        platform for all businesses
        </h1>
            <p className="font-size-17 fw400 text-color">
                Payment via cash and card is the norm today. Our aim with<br />
                Vpay is to make payment via transfer, the number one<br />
                channel for payments when shopping.
        </p>
            <Spacer height={5} />
            <KeyFeatures content={kFeatures} />
        </AboutBody>
    )
}

export const AboutBody = ({ children, image }) => (
    <div className="p-relative full-width full-height small-content-padding d-flex j-center">
        <div className="d-grid align-center merchant-about-grid-temp grid-col-gap80">
            <div>
                <img src={image} className="no-mobile full-width" alt='vpay on a mobile app' />
            </div>
            <div className="d-flex flex-vertical j-center">
                {children}
            </div>
        </div>
    </div>
)

export default MerchantAbout

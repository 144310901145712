import { Link } from 'react-router-dom';
import './app_icon.css';
import appIcon from '../../assets/images/vpayLogo.svg';

export default function AppIcon({ className, light, height }) {
	return (
		<Link to='/' className='app-icon-link'>
			<img src={appIcon} className='app-icon' alt='vpay identity (home page)' style={{ height: `${height}px` }}/>
		</Link>
	);
}
import { FormInput } from "../../components/form_input/form_input";
import { FormDropdown } from "../../components/form_dropdown/form_dropdown"
import { ColouredBackground, FormLayout } from "../../components/layout/layout";
import { industries } from '../../mock/industries'


export default function SecondarySignUp() {

    return (
        <ColouredBackground
        >
            <FormLayout
                headerText="TELL US ABOUT YOURSELF"
                buttonText="Continue"
            >
                <FormInput label="Legal Business Name" />
                <FormDropdown options={industries} label="Business Industry" className="form-btn-width"
                />
                <div className="d-grid dropdown-grid-temp-col-1fr grid-gap10 form-btn-width">
                    <FormDropdown className="small-dropdown-width" label="State" />
                    <FormDropdown className="small-dropdown-width " label="LGA" />
                </div>
                <FormInput label="City" />
                <FormInput label="Street Address" />
            </FormLayout>
        </ColouredBackground>
    )
}

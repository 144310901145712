import { useState } from 'react';

export const useInput = (val, type, isNumber) => {
    const [value, setValue] = useState(val);
    const onChange = (e) => {
        const { target: { value } } = e;
        if (isNumber) {
            if (isNaN(Number(value))) return;
          }
        setValue(value);
    }
    const reset = () => setValue('');
    return {
        type: type || 'text', value, onChange
    }
}


import { useState } from 'react';
import './form_input.css'


export const FormInput = ({ label, value, onChange, type, isPassword, icon }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown => !passwordShown);
  };
  return (
    <div className="input-container p-relative">
      {
        label && <label className="input-label fw200 ">{label}</label>
      }
      <div className="app-input-wrapper">
        <AppInput value={value} onChange={onChange} icon={icon} type={type === "password" ? (passwordShown ? "text" : "password") : "text"} />
        {
          isPassword && <span className="show fw400 text-bold-400 c-pointer" onClick={togglePasswordVisiblity} >{!passwordShown ? "Show" : "Hide"}</span>
        }
      </div>
    </div>
  )
}
export const AppInput = ({ value, onChange, type, icon }) => {
  return (
    <div className='p-relative'>
      <input className="app-input" value={value} type={type} onChange={onChange} autoComplete="new-password" />
      {icon && icon}
    </div>
  )
}

export const SearchIcon = ({ className }) => {
  return (
    <svg className={className || ''} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.96667" cy="9.96667" r="9.21667" stroke="#200E32" strokeOpacity="0.3" strokeWidth="1.5" />
      <path d="M16.8662 16.8672L22.9995 23.0005" stroke="#200E32" strokeOpacity="0.3" strokeWidth="1.5" strokeLinecap="round" />
    </svg>

  );
}


import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Switch } from 'react-router';
import { getUsableNavLink, MERCHANT_MAPPING } from '../../constants/title';
import AppNav from '../app_nav/app_nav';
import CircleAvatar from '../circle_avatar/circle_avatar';
import defaultUser from '../../assets/images/defaultUser.png';
import { Spacer } from '../layout/layout';
import './merchant_widgets.css';
import MerchantCompliance from '../../pages/merchant/merchant_compliance';
import { CustomInputFile } from '../image_upload/image_upload';
import { DashboardHeader, DashboardLayout, NotificationCard } from '../../pages/cashpoint_parent/cashpoint_desktop/cashpoint_dashboard/cashpoint_dashboard';
import { DashBoardWrapper } from '../../pages/cashpoint_parent/cashpoint_desktop/cashpoint_wrapper/cashpoint_wrapper';

export function CardDropDown({ className, merchant, businesses }) {
    return (
        <button className={(className || '') + ' unstyle-button c-pointer card-dropdown full-width'}>
            <div className='d-flex j-space-between card-dropdown-item align-center'>
                <div>
                    <p className='merchant-name'>QMart Nigeria Ltd</p>
                    <p>2039384474848</p>
                </div>
                <FontAwesomeIcon icon={faAngleDown} />
            </div>
        </button>
    );
}

export function MerchantHeader() {
    return (
        <DashboardHeader titleMap={MERCHANT_MAPPING} backgroundColor='white' >
            <div className='d-flex j-center align-center'>
                <NotificationCard borderRadius={30} />
                <Spacer width={10} />
                <UserIcon />
            </div>
        </DashboardHeader>
    );
}

export const UserIcon = ({ onClick }) => {
    return (
        <button onClick={onClick} className='unstyle-button c-pointer d-flex align-center'>
            <CircleAvatar img={defaultUser} size={35} />
            <Spacer width={5} />
            <FontAwesomeIcon className='user-icon-angle fw200' icon={faAngleDown} />
        </button>
    );
}

export function MerchantDashboard() {
    const { links, keys } = getUsableNavLink(1);
    return (
        <DashBoardWrapper background='#F8F8F8'>
            <AppNav links={links} navKeys={keys} beforeNavWidget={<CardDropDown />} />
            <DashboardLayout header={<MerchantHeader />}>
                <Spacer height={20} />
                <Switch>
                    <Route exact path='/merchant/compliance' component={MerchantCompliance} />
                </Switch>
            </DashboardLayout>
        </DashBoardWrapper>
    );
}

export function RegistrationContainer({ children }) {
    return (
        <div className='reg-container margin-auto'>
            {children}
        </div>
    );
}

export function TwoRowText({ title, description }) {
    return (
        <div className='two-row-text'>
            <p className='text-capitalize font-size-17 faded-text-color fw300'>{title}</p>
            <Spacer height={10} />
            <p className='text-color font-size-18 half-width fw400'>{description}</p>
        </div>
    );
}

function InfoCircle({ className }) {
    return (
        <svg className={className || ''} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 4.47779 4.47779 0 10 0C15.5229 0 20 4.47745 20 10C20 15.5225 15.5229 20 10 20C4.47779 20 0 15.5222 0 10ZM18.5 10C18.5 5.30586 14.6944 1.5 10 1.5C5.30621 1.5 1.5 5.30621 1.5 10C1.5 14.6938 5.30621 18.5 10 18.5C14.6944 18.5 18.5 14.6941 18.5 10ZM9.99512 5.45312C10.3748 5.45312 10.6886 5.73528 10.7383 6.10135L10.7451 6.20312V10.6221C10.7451 11.0363 10.4093 11.3721 9.99512 11.3721C9.61542 11.3721 9.30163 11.09 9.25196 10.7239L9.24512 10.6221V6.20312C9.24512 5.78891 9.5809 5.45312 9.99512 5.45312ZM10.7551 13.7969C10.7551 13.3827 10.4193 13.0469 10.0051 13.0469L9.89335 13.0537C9.52727 13.1034 9.24512 13.4172 9.24512 13.7969C9.24512 14.2111 9.5809 14.5469 9.99512 14.5469L10.1069 14.54C10.473 14.4904 10.7551 14.1766 10.7551 13.7969Z" fill="#AE207C" />
        </svg>

    );
}

export function DocumentUpload({ clickable, text, fileProps }) {
    return (
        <CustomInputFile fileProps={fileProps} className='full-width document-upload' clickable={clickable}>
            <div className='d-flex align-center'>
                <InfoCircle />
                <Spacer width={10} />
                <p className='accent-color fw400'>{text}</p>
            </div>
        </CustomInputFile>
    );
}
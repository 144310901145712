import HomeAppArea from "../../components/home_app_area/home_app_area";
import Layout, { Spacer } from "../../components/layout/layout";
import MerchantAbout from "../../components/merchant_about/merchant_about";
import MerchantFeatures from "../../components/merchant_features/merchant_features";
import MerchantHeader from "../../components/merchant_header/merchant_header";
import MerchantInfo from "../../components/merchant_info/merchant_info";
import { PinkGradient } from "../home/home";

export default function MerchantPage() {
    return (
        <div>
            <Layout>
                <MerchantHeader />
                <MerchantAbout />
                <Spacer height={80} className='no-mobile' />
                <MerchantFeatures />
                <PinkGradient>
                    <Spacer height={80} />
                    <MerchantInfo />
                    <Spacer height={30} className='no-mobile' />
                    <HomeAppArea />
                </PinkGradient>
            </Layout>
        </div>
    );
}
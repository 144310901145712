import { DashboardLayout } from "../cashpoint_dashboard/cashpoint_dashboard";

const CashpointTransactions = () => {
    return (
        <DashboardLayout>
            <div>
                Transactions Yeeee!!!
            </div>
        </DashboardLayout>
    )
}

export default CashpointTransactions;
import { useEffect } from "react";
import { useLocation } from "react-router";
export default function useTitle(title, mapping) {
    const { pathname } = useLocation();
    useEffect(() => {
        document.title = title + ' :: ' + (mapping[pathname]?.title || 'Page');
    }, [pathname])
}

export function useTitleName(mapping) {
    const { pathname } = useLocation();
    const titleName = mapping[pathname]?.title
    console.log({ pathname, mapping })
    return titleName || ''
}
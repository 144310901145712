import { Points } from "../../components/cashier_about/cashier_about";
import DeveloperHeader from "../../components/developer_header/developer_header";
import HomeAppArea from "../../components/home_app_area/home_app_area";
import Layout, { Spacer } from "../../components/layout/layout";
import { AboutBody } from "../../components/merchant_about/merchant_about";
import { API_FEATURES } from "../../components/merchant_features/feature";
import { Features } from "../../components/merchant_features/merchant_features";
import { PinkGradient } from "../home/home";

import developer_laptop from '../../assets/images/developer-laptop.png'
import google from "../../assets/images/google.png"
import amazon from "../../assets/images/amazon.png"
import slack from "../../assets/images/slack.png"
import microsoft from "../../assets/images/microsoft.png"
import facebook from "../../assets/images/facebook.png"

const content = [
    {
        title: 'Payment Verification',
        content: <>Cashier can instantly verify payment<br /> without depending on the business owner</>,
    },
    {
        title: 'Fake Alert Elimination',
        content: <>Eliminate risk of bank transfer reversal<b /> and customer fraud.</>,
    },
    {
        title: 'Fast Payment Verification',
        content: <>Give customer immediate verification,<br /> eliminating customers wait time.</>,
    },
]

export default function DeveloperPage() {
    return (
        <div>
            <Layout>
                <DeveloperHeader />
                <DeveloperAbout />
                <Spacer height={80} className='no-mobile' />
                <PinkGradient>
                    <TrustedBusinesses />
                    <Spacer height={200} className='no-mobile' />
                    <HomeAppArea />
                </PinkGradient>
            </Layout>
        </div>
    );
}

export const DeveloperAbout = () => {
    return (
        <>
            <AboutBody image={developer_laptop}>
                <h1 className="fw500 font-size-30 text-color">
                    Why you should <br />use VPay API
           </h1>
                <Spacer height={30} />
                <Points content={content} />
            </AboutBody>
            <Spacer height={100} />
            <h3 className="no-margin fw500 text-color font-size-bigger text-center line-height17">Vpay API Features</h3>
            <Spacer height={60} />
            <div className='d-grid small-content-padding' style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr", gridGap: "5px" }}>
                <Features features={API_FEATURES} />
            </div>
        </>
    )
}

export const TrustedBusinesses = () => (
    <div className="p-relative full-width full-height">
        <Spacer height={80} />
        <h2 className="no-margin text-color font-size-large text-center fw500 line-height17">Built for Developers, Trusted by Businesses</h2>
        <p className="no-margin text-color fw400 font-size-20 text-center">
            VPay Developer API was made for creators like you. We build our APIs to be simple to use,<br />
            powerful in production, and endlessly scalable. Little wonder we are trusted by these companies.
        </p>
        <Spacer height={80} />
        <div className='d-grid small-content-padding' style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", gridGap: "20px", justifyItems: "center" }}>
            <img src={google} alt="google" />
            <img src={slack} alt="gooslackgle" />
            <img src={microsoft} alt="microsoft" />
            <img src={facebook} alt="facebook" />
            <img src={amazon} alt="amazon" />
        </div>
    </div>
)
import { Spacer } from '../layout/layout'

import message from '../../assets/images/message.png'
import mail from '../../assets/images/mail.png'
import call from '../../assets/images/call.png'

export const CardContents = [
    {
        icon: call,
        title: "Call Admin",
        content: <>Get quick answers to any<br /> questions about Vpay</>,
        buttonText: "",
    },
    {
        icon: message,
        title: "Get in touch",
        content: <>Send us a message or chat<br /> when someone is available</>,
        buttonText: "",
    },
    {
        icon: mail,
        title: "Suggest an improvement",
        content: <>If you spot something that<br /> can be better, let us know</>,
        buttonText: "",
    }
]

export const ContactCards = () => {
    return CardContents.map((content, idx) => <ContactCard {...content} key={idx} />)
}


export const ContactCard = ({ icon, title, content, buttonText }) => (
    <div className="p-relative d-flex flex-vertical j-center align-center" style={{ padding: "10px 30px", height: "300px", maxWidth: "400px", minWidth: "300px", width: "calc((100vw - 400px)/ 3)", background: "#FFFFFF", border: "0.5px solid rgba(25, 0, 65, 0.15)", borderRadius: "5px" }}>
        <img src={icon} alt="" />
        <Spacer height={20} />
        <h3 className="no-margin fw500 font-size-20 text-center">{title}</h3>
        <Spacer height={10} />
        <p className="no-margin text-center fw400 font-size-17">{content}</p>
    </div>
)
import gridOne from '../../assets/images/gridOne.png';
import gridTwo from '../../assets/images/gridTwo.png';
import gridThree from '../../assets/images/gridThree.png';
import gridFour from '../../assets/images/gridFour.png';
import gridFive from '../../assets/images/gridFive.png';
import gridSix from '../../assets/images/gridSix.png';
import gridSeven from '../../assets/images/gridSeven.png';
import gridEight from '../../assets/images/gridEight.png';
import './about_images.css';
export default function AboutImages() {
    return (
        <div className='content-padding mobile-spacing'>
            <div className='about-image-container'>
                <img src={gridOne} alt='grid item' />
                <img src={gridTwo} alt='grid item' />
                <img src={gridThree} alt='grid item' />
                <img src={gridFour} className='' alt='grid item' />
                <img src={gridFive} alt='grid item' />
                <img src={gridSix} alt='grid item' />
                <img src={gridSeven} alt='grid item' />
                <img src={gridEight} alt='grid item' />
            </div>
        </div>
    );
}
import useViewport from "../../hooks/use_viewport";

import CashpointMobile from './cashpoint_mobile/mobile_wrapper/mobile_wrapper'
import CashpointDesktop from './cashpoint_desktop/cashpoint_wrapper/cashpoint_wrapper'


const CaspointParent = () => {
    const { width } = useViewport();
    const breakpoint = 700;

    return width < breakpoint ? <CashpointMobile /> : <CashpointDesktop />;
}

export default CaspointParent;
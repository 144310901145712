import vpayWithWoman from '../../assets/images/vpayWithWoman.png';
import { GradientHeader } from '../home_header/home_header';
import { Spacer } from '../layout/layout';
import './about_header.css';
export default function AboutHeader() {
    return (
        <GradientHeader>
            <div className='about-header-padding flex-vertical-mobile d-flex j-space-between'>
                <div className='half-width about-header-text'>
                    <p className='fw700 font-size-bigger text-center-mobile line-height12'>
                        About Vpay
                    </p>
                    <Spacer height={40} />
                    <p className='font-size-medium line-height17 text-justify'>
                        Convallis turpis erat tempus, viverra aliquet.
                        Nullam viverra nam auctor sit ipsum malesuada a, duis volutpat.
                        Convallis turpis erat tempus, viverra aliquet.
                        Nullam viverra nam auctor sit ipsum malesuada a, duis volutpat.
                        Convallis turpis erat tempus, viverra aliquet.<br /> <br />
                        Nullam viverra nam auctor sit ipsum malesuada a, duis volutpat.
                        Convallis turpis erat tempus, viverra aliquet.
                        Nullam viverra nam auctor sit ipsum malesuada a, duis volutpat.


                    </p>
                </div>
                <Spacer width={50} />
                <div className='half-width no-mobile'>
                    <img src={vpayWithWoman} className='about-header-image' alt='vpay with a lady in it' />
                </div>
            </div>

        </GradientHeader>
    );
}

import { useHistory } from "react-router";
import { FormInput } from "../../components/form_input/form_input";
import { ColouredBackground, FormLayout } from "../../components/layout/layout";
import { useInput } from "../../hooks/use_input";

const FORM_LAYOUT_TEXT = "By clicking the “Create My Account” button, you agree to VPay terms of acceptable use"

export default function PrimarySignUp() {
    const history = useHistory()
    const firstnameProps = useInput('', "text")
    const lastnameProps = useInput('', "text")
    const emailProps = useInput('', "text")
    const phoneProps = useInput('', "text", true)
    const referralProps = useInput('', "text")
    const passwordProps = useInput('', "password")
    const confirmPasswordProps = useInput('', "password")

    const onClick = () => {
        history.push('/more-details')
    }

    return (
        <ColouredBackground
            bottomText="Already have an account?"
            linkText="Login"
            lastText="Forgot your password?"
            route="/login"
            onClick={() => history.push('/forgot-password')}
        >
            <FormLayout
                headerText="create your account"
                buttonText="Create My Account"
                bottomText={FORM_LAYOUT_TEXT}
                onClick={onClick}
            >
                <FormInput label="First Name" {...firstnameProps} />
                <FormInput label="Last Name" {...lastnameProps} />
                <FormInput label="Phone No" {...phoneProps} />
                <FormInput label="Email Address" {...emailProps} />
                <FormInput label="Referral Code (Optional)" {...referralProps} />
                <FormInput label="Password" isPassword {...passwordProps} />
                <FormInput label="Confirm Password" isPassword {...confirmPasswordProps} />
            </FormLayout>
        </ColouredBackground>
    )
}


// export const BottomContent = () => (

// )
import './store_button.css';

import googlePlay from '../../assets/images/googlePlay.svg';
import appStore from '../../assets/images/appStore.svg';

export default function StoreButton({ className, orientation }) {
    return (
        <div className={`d-flex ${className || ''} ${orientation === 'vertical' ? 'flex-vertical' : ''}`}>
            <button className={`unstyle-button store-button no-padding c-pointer ${orientation === 'vertical' ? 'mb-2' : 'mr-2'}`}>
                <img src={googlePlay} className='store-icon' alt='link to google play' />
            </button>
            <button className='unstyle-button store-button no-padding c-pointer'>
                <img src={appStore} className='store-icon' alt='link to app store' />
            </button>
        </div>
    );
}
import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'

import menu_icon from '../../../../assets/images/menuIcon.svg'
import cashier_logo from '../../../../assets/images/cashier-logo.png';
import merchant_logo from '../../../../assets/images/merchant-logo.png';

import './mobile_dashboard.css'
import { Spacer } from '../../../../components/layout/layout'
import { useTitleName } from '../../../../hooks/use_title';
import { CASHPOINT_MAPPING } from '../../../../constants/title';


export default function MobileDashboard() {
    return (
        <MobileLayout>
            Hello
        </MobileLayout>
    )
}


export const MobileLayout = ({ children }) => (
    <>
        <CashpointMobileHeader />
        <Spacer height={10} />
        <div className="content-padding" >
            {children}
        </div>
    </>
)


export const CashpointMobileHeader = () => {
    const match = useRouteMatch()
    const titleName = useTitleName(CASHPOINT_MAPPING);
    const [navOpen, setNavOpen] = useState(null);

    const onNavMenuClick = () => {
        setNavOpen(true);
    }

    const onBodyClick = (e, value) => {
        // console.log({ value })
        setNavOpen(value);
        e.stopPropagation();
    }

    useEffect(() => {
        if (navOpen) {
            document.body.style.overflow = 'hidden';
        }
        else {
            return () => document.body.style.overflow = null;
        }
        return () => document.body.style.overflow = null;
    }, [navOpen])

    //close nav on new route
    // useEffect(() => {
    //     setNavOpen(null);
    // }, [pathname])

    return (
        <div className="mobile-nav-header d-flex align-center content-padding j-space-between">
            <h3 className="no-margin fw500 font-size-17">{titleName}</h3>
            <button className='unstyle-button c-pointer' onClick={onNavMenuClick}>
                <img src={menu_icon} className='menu-icon' alt='menu icon' />
            </button>
            {
                navOpen && <CashpointMobileNavigation />
            }
        </div>
    )
}

export const CashpointMobileNavigation = ({ isMerchant }) => {
    return (
        <div className="m-nav full-height">
            <div className="p-relative">
                <img src={isMerchant ? merchant_logo : cashier_logo} alt="cashier app logo" />
            </div>
            Hello
        </div>
    )
}
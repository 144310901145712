import CashierAbout from "../../components/cashier_about/cashier_about";
import CashierAppArea from "../../components/cashier_app_area/cashier_app_area";
import CashierHeader from "../../components/cashier_header/cashier_header";
import HomeAppArea from "../../components/home_app_area/home_app_area";
import Layout, { Spacer } from "../../components/layout/layout";
import { PinkGradient } from "../home/home";

export default function CashierPage() {
    return (
        <div>
            <Layout>
                <CashierHeader />
                <Spacer height={80} className='no-mobile' />
                <CashierAbout />
                <PinkGradient>
                    <Spacer height={80} />
                    <CashierAppArea />
                    <Spacer height={150} className='no-mobile' />
                    <HomeAppArea />
                </PinkGradient>
            </Layout>
        </div>
    );
}
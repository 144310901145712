import AppButton from '../app_button/app_button';
import { Spacer } from '../layout/layout';
import StoreButton from '../store_button/store_button';
import './about_app_area.css';

export default function AboutAppArea() {
    return (
        <div className='content-padding about-app-area mobile-spacing'>
            <div className='p-relative d-flex align-center flex-vertical-mobile j-space-between home-app-area'>
                <div className='home-app-text-area'>
                    <p className='fw500 font-size-bigger'>
                        All-in-one platform for business
                    </p>
                    <Spacer height={20} />
                    <p className='home-app-text font-size-medium'>
                        Sign up today and start receiving payments from over of
                        50% of your unstapped customer base, transaction notifications are instant.
                    </p>
                    <Spacer height={25} />

                </div>
                <div>
                    <AppButton className='full-width about-download-button' name='Download Vpay app' />
                    <Spacer height={20} />
                    <StoreButton />
                </div>
            </div>
            <Spacer height={70} />
        </div>
    );
}
import { DashboardLayout } from "../cashpoint_dashboard/cashpoint_dashboard";

const CashpointSettings = () => {
    return (
        <DashboardLayout>
            <div>
                Settings Yooo!!! Lets change some things!!!!
            </div>
        </DashboardLayout>
    )
}

export default CashpointSettings;
import AppButton from '../app_button/app_button';
import { FormContainer } from '../layout/layout';
import RegistrationStep from '../registration_step/registration_step';
import './stepper_layout.css';

export default function StepperLayout({ position, stepperClass, className, items, children }) {
    return (
        <div className={`${className || ''} stepper-layout d-flex flex-vertical`}>
            <RegistrationStep items={items} position={position} className={stepperClass || ''} />
            <div className='form-content-stepper'>
                {children}
            </div>
        </div>
    );
}


export function RegistrationForm({ children, onNext, onEdit, hideButtonWhen }) {
    return (
        <FormContainer className='reg-form-margin-top'>
            <div className='d-flex flex-vertical full-width full-height'>
                <div className='flex-one'>
                    {children}
                </div>
                {
                    !hideButtonWhen && (
                        <div className='d-flex full-width j-space-between compliance-button-container'>
                            <AppButton isActive={!!onEdit} onClick={onEdit} className='fw400 compliance-button' name='Edit' />
                            <AppButton isActive={!!onNext} onClick={onNext} className='fw400 compliance-button' name='Next' />

                        </div>
                    )
                }
            </div>

        </FormContainer>
    )
}
import { useHistory } from 'react-router';
import { Spacer } from '../layout/layout';
import mobileApp from '../../assets/images/mobileApp.png';
import ebeleAdekunle from '../../assets/images/ebeleAdekunle.png';
import './home_header.css';
import AppButton from '../app_button/app_button';
import CircleAvatar from '../circle_avatar/circle_avatar';
import colors from '../../utils/color/colors';
export default function HomeHeader() {
    const { textColor } = colors();
    const history = useHistory()
    return (
        <GradientHeader>
            <div className='body-header-padding d-flex j-space-between align-center'>
                <div className='d-flex flex-vertical'>
                    <div>
                        <h1 className='font-size-extra fw700 line-height12 brown-text no-margin'>
                            Simplifying<br /> Payment<br /> Processes
                        </h1>
                        <Spacer height={20} />
                        <p className='header-rider fw400 font-size-20' style={{ maxWidth: "500px" }}>
                            The easiest way to mange your business finance, send and receieve
                            payments via bank transfers and confirm payments instantly.
                        </p>
                        <Spacer height={10} />
                        <div className='d-flex header-action align-center'>
                            <AppButton isActive={true} name='Create free account' onClick={() => history.push('/signup')} className=' fw500 font-size-20 header-account-button' />
                            <Spacer width={40} />
                            <button className='unstyle-button c-pointer action-color font-size-20'>Get the App</button>
                        </div>
                    </div>
                    <Spacer height={30} className='no-mobile' />
                    <div className='header-testimony'>
                        <p className='fw400 font-size-20'>Simple to use and effective,<br />good decision for my business </p>
                        <Spacer height={10} />
                        <div className='d-flex user-testimony align-center'>
                            <CircleAvatar size={50} img={ebeleAdekunle} />
                            <Spacer width={10} />
                            <div className='d-flex flex-vertical align-start'>
                                <p className='fw500 font-size-20 no-margin'>Ebele Adekunle</p>
                                <p className='font-size-17 no-margin' style={{ color: textColor.withOpacity(0.41) }}>CEO at Qmart</p>
                            </div>
                        </div>
                        <Spacer height={30} />
                    </div>
                </div>
                <img src={mobileApp} className='mobile-app no-mobile' alt='vpay on a mobile app' />
            </div>

        </GradientHeader>
    );
}

export const GradientHeader = ({ children }) => {
    return (
        <div className='gradient-header'>
            {children}
        </div>
    );
}

export const Content = ({ children }) => {
    return (
        <div className='content-padding top-content-padding p-relative'>
            {children}
        </div>
    );
}
import React from 'react'
import { HeadContentContainer, HeaderImage } from '../merchant_header/merchant_header'

import cashier_background from '../../assets/images/cashier-background.png'
import google_download from '../../assets/images/googlePlay.svg'
import apple_download from '../../assets/images/appleStore.svg'
import { Spacer } from '../layout/layout'

const title = "VPay for Cashiers"
const content = <>
    Empowers cashiers via Vpay web and mobile apps to<br /> instantly
receive payment at point of sales and confirm with<br /> zero
access to the business bank account.
</>

export default function CashierHeader() {
    return (
        <HeaderImage background={cashier_background}>
            <HeadContentContainer title={title} content={content}>
                <Spacer height={10} />
                <ButtonLinkToStore />
            </HeadContentContainer>
        </HeaderImage>
    )
}


export const ButtonLinkToStore = () => (
    <div className="d-flex align-center">
        <img src={google_download} alt="download andriod app" className="c-pointer" />
        <Spacer width={20} />
        <img src={apple_download} alt="download IOS app" className="c-pointer" />
    </div>
)